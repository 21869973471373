import styles from "./Footer.module.css";

const Footer = () => {
    return (
        <footer className={styles["footer-container"]} >
            <div className={styles["footer-inner-container"]}>
                <div className={styles.content}>
                    <span>© www.wavesgacha All rights reserved.</span>
                    <span><b>문의메일</b> : devsurum@gmail.com</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;