import SideBar from "./side-navigation/Sidebar";
import styles from "./ToggleSideMenu.module.css";
import { useState} from "react";
import ReactDOM  from "react-dom";  
import HamburgerMenuIcon from "../../../assets/icons/header-menu-ic.svg";
const ToggleSideMenu = () => {

    // sideMenu 렌더링 변수
    const [menuOpen, setMenuOpen] = useState(false);

    // 토글버튼을 직접 누를경우 
    // 이전상태의 반대상태로 토글
    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    }

    // overlay의 공간을 클릭할경우 사이드바의 상태를 false
    const closeMenu = () => {
        setMenuOpen(false);
    }

    return (
        <>
            <span>
              {/* ToggleSideMenu버튼 클릭시 전체 오버레이 창이 잡히며 SideBar 컴포넌트(사이드바) 렌더링 */}
                 <button  className={styles["header__right-group__button"]} onClick={toggleMenu}>
                     <img src={HamburgerMenuIcon} alt="햄버거 메뉴"></img>
                 </button>

            </span>
             {  ReactDOM.createPortal(
                <>
                {menuOpen && <div className={styles["header__right-group__overlay"]} onClick={closeMenu}></div>}
                <SideBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </>
                ,
                document.getElementById("sidebar-portal")
            )}
        </>
    )
}

export default ToggleSideMenu;