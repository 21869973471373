import logoIcon from "../../../assets/icons/header-logo-ic.svg";
import styles from "./Logo.module.css";
const Logo = ({width,height}) => {

    return (
        <div>
            <img src={logoIcon} alt="logo" width={width} height={height} className={styles.logo} ></img>
        </div>
    )
}

export default Logo;