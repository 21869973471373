import {Outlet} from 'react-router-dom'
import Header from '../components/layout/header/Header';
import Footer from '../components/layout/footer/Footer';
import styles from "./Root.module.css";
function RootLayout() {
    return (
        <div className={styles['root-layout']} >
        <Header/>
        <main className={styles['main-content']}>
        <Outlet/>
        </main>
        <Footer/>     
        </div>
    )
}

export default RootLayout;