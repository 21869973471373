import { NavLink } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { useRef, useEffect } from "react";
import Logo from "../Logo";
import MenuItemsData from "../navItemsData";
import SideItems from "./SidebarItems";

const Sidebar = ({ menuOpen, setMenuOpen }) => {

  // sidebar(nav태그)에 대한 ref변수
  let sideBarRef = useRef(null);

  // depthLevel에 따른 DropDown CSS 변동
  const depthLevel = 0;

  useEffect(() => {
    // 만약 SideBar 메뉴가 열리지 않았다면 리스너 등록을 하지 않는다. (최적화)
    if (!menuOpen) {
      return;
    }

    // ref.current(사이드바 영역)을 제외한 곳을 클릭하게 되면 닫히게하는 핸들러
    const handler = (event) => {
      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [menuOpen, setMenuOpen]);

  return (
    <nav
      ref={sideBarRef}
      className={
        menuOpen
          ? `${styles.sidebar} ${styles["sidebar--open"]}`
          : styles.sidebar
      }
    >
      <div className={styles.sidebar__logo}>
        <NavLink to="/">
          <Logo width="150" height="50" />
        </NavLink>
      </div>

      <ul className={styles.sidebar__menus}>
        {MenuItemsData.map((menu, index) => {
          return (
            <SideItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
              setMenuOpen={setMenuOpen}
              menuOpen={menuOpen} 
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
