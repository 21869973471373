import styles from "./SidebarDropdown.module.css";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames/bind";
import SideItems from "./SidebarItems";

// CSSTransition 컴포넌트 사용중 findDOMNode오류를 반환하여
// CSSTransition 컴포넌트의 nodeRef와 useRef를 사용하여 오류해결
import { useRef } from "react";

const cx = classNames.bind(styles);

const SidebarDropdown = ({
  submenus,
  dropdown,
  depthLevel,
  navLinkClickHandler,
  setMenuOpen,
  menuOpen,
}) => {
  const nodeRef = useRef(null);

  const dropdownClass = cx({
    "dropdown__primary": depthLevel === 1,
    "dropdown__secondary": depthLevel > 1,
    "dropdown--on": dropdown,
    "dropdown--off": !dropdown,
  });

  return (
    <CSSTransition
      in={dropdown}
      timeout={300}
      classNames={{
        enter: styles["dropdown-enter"],
        enterActive: styles["dropdown-enter-active"],
        exit: styles["dropdown-exit"],
        exitActive: styles["dropdown-exit-active"],
      }}
      unmountOnExit
      nodeRef={nodeRef}
    >
      <ul ref={nodeRef} className={cx(dropdownClass)}>
        {submenus.map((submenu, index) => (
          <SideItems
            items={submenu}
            key={index}
            depthLevel={depthLevel}
            dropNavLinkClickHandler={navLinkClickHandler}
            setMenuOpen={setMenuOpen}
            menuOpen={menuOpen}
          />
        ))}
      </ul>
    </CSSTransition>
  );
};

export default SidebarDropdown;
