import { NavLink, useLocation } from "react-router-dom";
import Dropdown from "./TopDropdown";
import styles from "./TopMenuItems.module.css";
import { useState, useEffect, useRef } from "react";
import classNames from "classnames/bind";

// classNames 바인딩
const cx = classNames.bind(styles);

const MenuItems = ({ items, depthLevel, isMenuOpen,setIsMenuOpen}) => {
  const [dropdown, setDropdown] = useState(false);

  // 현재 활성화된 링크 하이라이트 강조
  const location = useLocation();
  const isActive = items.url
    ? location.pathname === items.url || location.pathname.startsWith(items.url)
    : false;

// 클래스 네임을 결정하는 함수 추상화
const getClassNames = (isTopLevel) => ({
  buttonClass: cx({
    "menu-item__top": isTopLevel, // 최상단 메뉴
    "menu-item__top-button": isTopLevel, // 최상단 메뉴의 버튼
    "menu-item__submenu": !isTopLevel, // 하위 메뉴
    "menu-item__submenu-button": !isTopLevel, // 하위 메뉴의 버튼
  }),
  linkClass: cx({
    "menu-item__top": isTopLevel, // 최상단 메뉴
    "menu-item__top-link": isTopLevel, // 최상단 메뉴의 링크
    "menu-item__submenu": !isTopLevel, // 하위 메뉴
    "menu-item__submenu-link": !isTopLevel, // 하위 메뉴의 링크
  }),

  lineClass: cx({
    "menu-item__top--line": isTopLevel, // 최상단 메뉴의 밑줄 애니메이션 기본 클래스
    "menu-item__top--line-on": isTopLevel && isActive, // 활성화 상태에서 밑줄 애니메이션 클래스
  }),
  arrowClass: cx({
    "menu-item__arrow--down": isTopLevel, // 드롭다운 화살표
    "menu-item__arrow--right": !isTopLevel , // 오른쪽 화살표 (드롭다운 닫힘 상태)
  }),
});

const { buttonClass, linkClass, arrowClass ,lineClass} = getClassNames(depthLevel === 0);


  // 요소 참조를 위한 useRef()훅 사용
  // 해당 ref는 li요소의 DOM 요소를 참조하기 위해서 사용.
  let ref = useRef();

  // 메뉴요소 이외의 것을 클릭할경우 메뉴요소를 OFF로 유도하는 useEffect 함수
  useEffect(() => {
    const handler = (event) => {
      // 드롭다운이 열려있고, 터치된 요소가 현재 메뉴 항목의 외부에있는경우
      // 현재 메뉴항목을 off
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    // mousedown 이벤트 리스너 등록 (touchstart는 터치스크린 유저를 위한 기능 이므로 동일기능을가진다.)
    // 왜 useEffect에 기술해야하느냐?
    // 그냥 기술해버리면 리렌더링마다 등록되므로 중복 등록, 즉 메모리 누수가 필연적이다.
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      // 이벤트 리스너 정리
      // 언제실행돼? => 의존성 배열이 변할때 or 컴포넌트가 언마운트될때(언마운트되기직전에 실행됨.)
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

 // navLink버튼을 클릭하게 된다면
 const navLinkClickHandler = () => {
  // 전역변수를 false로 설정한다
  setIsMenuOpen(false);

  // 추후 아래에있는 useMenuOpen변수에 의존하는 useEffect 함수에의해 
  // 지역변수들의 DrdopDown 변수들 모두 false로 변경이된다. 

};

// 전역변수인 isMenuOpen이 false라면
// 지역변수인 dropDown변수가 false로변환(=드롭다운이 OFF가된다.)
  useEffect( () => {
    if(!isMenuOpen) {
      setDropdown(false);
    }
  },[isMenuOpen])

// 드롭다운이 켜지게되면 
// 전역변수인 isMenuOpen이 true로 설정된다
  const menuOpenOnClickHandler = () => {
    setIsMenuOpen(true);

    // 해당 드롭다운버튼을 누르게되면
    // ON일경우 OFF
    // OFF일경우 ON으로 변환이됨.
    setDropdown((prev) => !prev)
  }

  return (
    <li
      ref={ref}
      className={`${styles["menu-item"]} `}
    >
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-expanded={dropdown ? "true" : "false"}
            aria-haspopup="menu"
            onClick={menuOpenOnClickHandler}
            // className={`${buttonClass} ${(dropdown && depthLevel === 1) ? styles['menu-item__sub-button--active'] : "" }`}
            className={buttonClass}
          >
            {items.title} 
            <span className={arrowClass} />
            <span className={cx(lineClass)} /> 
          </button>
          <Dropdown
            depthLevel={depthLevel+1}
            dropdown={dropdown}
            submenus={items.submenu}
            navLinkClickHandler={navLinkClickHandler}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            setDropdown={setDropdown}
          />
        </>
      ) : (
        <NavLink
          to={items.url}
          className={linkClass}
          end={true}
          onClick={navLinkClickHandler}
        >
          {items.title}
          <span className={cx(lineClass)} /> 
          </NavLink>
      )}
    </li>
  );
};

export default MenuItems;
