import styles from "./Header.module.css";
import TopNavBar from "../navigation/top-navigation/TopNavBar";
import Logo from "../navigation/Logo";
import { NavLink } from "react-router-dom";
import ToggleSideMenu from "../navigation/ToggleSideMenu";
const Header = () =>{
  return (
    <header className={styles["header"]}>
      <div className={styles.header__content}>
      
        {/* 로고 및 Nav 메뉴 렌더링 */}
         <div className={styles["header__left-group"]}>
           <NavLink to="/"><Logo/></NavLink>
           <TopNavBar/>
         </div>

        {/* 모바일 환경일 경우 사이드바를 Open할 수 있는 햄버거 메뉴 렌더링 */}
         <div className={styles["header__right-group"]}>
           <ToggleSideMenu/>
         </div>
      
      </div>
    </header>
  );
};

export default Header;
