const NavItemsData = [
  {
    title: "가챠시뮬",
    url: "/gacha",
    submenu: [
      {
        title: "한정가챠",
        submenu: [
          {
            title: "한정캐릭터",
            url: "/gacha/limited-char",
          },
          {
            title: "한정무기",
            url: "/gacha/limited-weapon",
          },
        ],
      },

      {
        title: "상시가챠",
        submenu: [
          {
            title: "상시캐릭터",
            url: "/gacha/always-char",
          },
          {
            title: "상시무기",
            url: "/gacha/always-weapon",
          },
        ],
      },
    ],
  },
  {
    title: "에코시뮬",
    url: "/echo",
  },

];

export default NavItemsData;
