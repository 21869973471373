import { useState, useEffect } from "react";
import SidebarDropdown from "./SidebarDropdown";
import { NavLink } from "react-router-dom";
import styles from "./SidebarItems.module.css";
import classNames from "classnames/bind";

// css조건이 다소 복잡할경우 classNames 라이브러리 사용하자.
const cx = classNames.bind(styles);

const SidebarItems = ({
  items,
  depthLevel,
  menuOpen,
  setMenuOpen,

}) => {

  // 개별 드롭다운 변수
  const [dropdown, setDropdown] = useState(false);


  // 드롭다운 클래스 결정 함수 추상화
  const getClassNames = (isTopLevel) => ({
    buttonClass: cx({
      "sidebar__top": isTopLevel,
      "sidebar__top--active": isTopLevel && dropdown,
      "sidebar__top-button": isTopLevel,

      "sidebar__submenu": !isTopLevel,
      "sidebar__submenu-button": !isTopLevel,
      "sidebar__submenu--active": !isTopLevel && dropdown,
    }),
    navlinkClass: cx({
      "sidebar__top": isTopLevel,
      "sidebar__top-link": isTopLevel,

      "sidebar__submenu": !isTopLevel,
      "sidebar__submenu-link": !isTopLevel,
    }),
  });

  const { buttonClass, navlinkClass } = getClassNames(depthLevel === 0);
  



  const arrowClass = cx({
    "button__arrow": true,
    "button__arrow--down": dropdown,
    "button__arrow--right": !dropdown,
  });

  // navLink버튼을 클릭하게 된다면
  const navLinkClickHandler = () => {

    // 사이드바 메뉴를 닫는다.
    setMenuOpen(false);
    
  };

  // 어떤 트리거로 인해 menuOpen이 false일경우 useEffect 함수
  useEffect(() => {
    // menuOpen이 false일경우
    // 열려있는 개별 드롭다운메뉴를 닫음
    if (!menuOpen) {
      setDropdown(false); // 드롭다운을 닫음
    }
  }, [menuOpen]);


  // 직접 드롭다운을 클릭시 해당 드롭다운의 ON/OFF를 담당
  const toggleDropdown = () => {
    setDropdown((prevState) => !prevState);
  };


  return (
    <li className={cx("sidebar__menu-item")}>
      {/* subMenu가 있을경우(=하위 메뉴가 있는경우) */}
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-expanded={dropdown ? "true" : "false"}
            aria-haspopup="menu"
            onClick={toggleDropdown}
            className={buttonClass}
          >
            <span className={cx("button_text")}>{items.title}</span>
            <span className={cx(arrowClass)} />
          </button>
          <SidebarDropdown
          // 드롭다운을 한번씩 거칠때마다 depthLevel을 +1늘림
            depthLevel={depthLevel+1}
            dropdown={dropdown}
            submenus={items.submenu}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />
        </>
      ) : (
      // subMenu가 없을경우(=하위 메뉴가 없는경우)
        <>
          <NavLink
            to={items.url}
            end={true}
            className={navlinkClass}
            onClick={navLinkClickHandler}
          >
            {items.title}
          </NavLink>
        </>
      )}
    </li>
  );
};

export default SidebarItems;
